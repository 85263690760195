import React from 'react';
import { Link } from "../../plugins/gatsby-plugin-atollon";


import Logo from './logo';
import Newsletter from './newsletter';
import { Facebook, Instagram, LinkedIn } from './icons';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__inner'>
        <div className='footer__logo'>
          <Link to='/' title='Wills Property'>
            <Logo color={'#38291D'} />
          </Link>
        </div>
        <div className='footer__headings'>
          <div className='footer__col'>
            <h5>Property</h5>
            <h5>Our Family</h5>
          </div>
          <div className='footer__col'>
            <h5>Contact</h5>
          </div>
          <div className='footer__col'>
            <h5>Social</h5>
          </div>
        </div>
        <div className='footer__columns'>
          <div className='footer__col'>
            <nav className='footer__nav'>
              <p className='footer__mobile-header'>Property</p>
              <ul>
                <li><Link to='/buy/'>Buy</Link></li>
                <li><Link to='/lease/'>Lease</Link></li>
                <li><Link to='/sold/'>Sold</Link></li>
                <li><Link to='/appraisals/'>Appraisals</Link></li>
              </ul>
            </nav>
            <nav className='footer__nav'>
              <p className='footer__mobile-header'>Our Family</p>
              <ul>
                <li><Link to='/our-story/'>Our Story</Link></li>
                <li><Link to='/news/'>News</Link></li>
                <li><Link to='/contact/'>Contact Us</Link></li>
                <li><Link to='/useful-links/'>Useful Links</Link></li>
              </ul>
            </nav>
          </div>
          <div className='footer__col'>
            <p className='footer__mobile-header'>Contact</p>
            <p>Suite 1C, 79 Oxford Street<br />Bondi Junction, 2022</p>
            <p>
              <Link to='tel:+61293871700'>+61 2 9387 1700</Link><br />
              <Link to='mailto:info@willsproperty.com.au'>info@willsproperty.com.au</Link>
            </p>
          </div>
          <div className='footer__col'>
            <p className='footer__mobile-header'>Social</p>
            <p>Subscribe to our newsletter</p>
            <Newsletter />
            <ul className='footer__social'>
              <li><Link to='https://www.instagram.com/willspropertyau/?hl=en'><Instagram color={'#38291D'} /></Link></li>
              <li><Link to='https://www.linkedin.com/company/wills-property/'><LinkedIn color={'#38291D'} /></Link></li>
              <li><Link to='https://www.facebook.com/willsproperty.com.au/'><Facebook color={'#38291D'} /></Link></li>
            </ul>
          </div>
        </div>
        <div className='footer__credits'>
          <div className='footer__col footer--desktop'>
            <p>© Wills Property, {new Date().getFullYear()}. All Rights Reserved.</p>
          </div>
          <div className='footer__col'>
            <ul>
              <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
            </ul>
          </div>
          <div className='footer__col footer--desktop'>
            <p>Design by <Link to='https://atollon.com.au' rel='noopener' target='_blank'>Atollon</Link></p>
          </div>
          <div className='footer__col footer--mobile'>
            <p>© Wills Property, {new Date().getFullYear()}. All Rights Reserved.<br />Design by <Link to='https://atollon.com.au' rel='noopener' target='_blank'>Atollon</Link></p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

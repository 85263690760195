import React, { Component } from 'react'

class Logo extends Component {

  render() {
    let { color } = this.props
    return (
      <svg width="78px" height="27px" viewBox="0 0 78 27" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-682.000000, -63.000000)">
            <g transform="translate(682.000000, 63.500000)">
              <g transform="translate(0.000000, -0.000000)">
                <path d="M39.1037183,22.952587 L39.1037183,8.95742139 L37.9885143,8.95742139 L33.4919088,11.7633261 L33.4919088,12.4834129 L34.8218504,12.4834129 C35.6135164,12.4834129 35.9370544,12.8069509 35.9370544,13.6701961 L35.9370544,22.952587 C35.9370544,24.2481706 35.470358,24.6432878 33.4919088,24.8236674 L33.4919088,25.7227022 L41.5145058,25.7227022 L41.5145058,24.8236674 C39.5718462,24.6432878 39.1037183,24.2481706 39.1037183,22.952587" fill={color}></path>
                <path d="M49.0326126,22.952587 L49.0326126,0 L47.9174086,0 L43.4208031,2.80590473 L43.4208031,3.52455993 L44.7507447,3.52455993 C45.5438423,3.52455993 45.8673803,3.8495295 45.8673803,4.71277469 L45.8673803,22.952587 C45.8673803,24.2481706 45.3992523,24.6432878 43.4208031,24.8236674 L43.4208031,25.7227022 L51.4434001,25.7227022 L51.4434001,24.8236674 C49.5007406,24.6432878 49.0326126,24.2481706 49.0326126,22.952587" fill={color}></path>
                <path d="M58.9616501,22.952587 L58.9616501,0 L57.8464461,0 L53.3498406,2.80590473 L53.3498406,3.52455993 L54.6812138,3.52455993 C55.4728797,3.52455993 55.7964177,3.8495295 55.7964177,4.71277469 L55.7964177,22.952587 C55.7964177,24.2481706 55.3282898,24.6432878 53.3498406,24.8236674 L53.3498406,25.7227022 L61.3724376,25.7227022 L61.3724376,24.8236674 C59.429778,24.6432878 58.9616501,24.2481706 58.9616501,22.952587" fill={color}></path>
                <g transform="translate(62.500955, 8.957994)">
                  <path d="M8.346135,6.65543424 C5.50444066,6.04414785 3.38140152,5.50444066 3.38140152,3.70493952 C3.38140152,2.37356635 4.46081589,1.36716277 6.65543424,1.36716277 C9.10201138,1.36716277 11.2236189,2.48236674 12.9143197,5.46865106 L13.7059857,5.18090267 L12.6637925,0.539707186 L11.7633261,0.539707186 L11.4039985,1.43874197 C10.3245842,0.611286388 8.70546259,0 6.43926505,0 C2.94906314,0 0.575496787,2.12303914 0.575496787,4.71277469 C0.575496787,8.12996581 3.92110871,8.99321099 6.97897223,9.71329776 C9.82066657,10.3245842 12.1598749,10.8642913 12.1598749,12.9873305 C12.1598749,15.0373588 10.2530049,15.900604 8.346135,15.900604 C5.28827147,15.900604 2.94906314,14.4976517 0.790234394,11.0804605 L0,11.3324193 L1.29415198,16.7652808 L2.23040795,16.7652808 L2.66131474,15.577066 C3.92110871,16.5491116 6.07993745,17.304988 8.3819246,17.304988 C12.0510745,17.304988 14.8226212,15.2177384 14.8226212,11.9794953 C14.8226212,8.3819246 11.5127989,7.44710022 8.346135,6.65543424" fill={color}></path>
                </g>
                <path d="M35.8153697,0.366914991 C32.4855052,0.998243556 30.796236,5.28870095 28.4212381,12.7715908 L26.0104506,20.1471118 L19.9047446,1.12565454 L17.9076849,1.12565454 L11.8005473,20.1113222 L6.79859264,4.56861417 C6.07993745,2.37399583 6.61964464,1.61811945 9.49712857,1.43917144 L9.49712857,0.538705077 L1.01720165e-14,0.538705077 L1.01720165e-14,1.43917144 C1.90686995,1.61811945 2.33777675,2.37399583 3.05786353,4.56861417 L9.82066657,25.7231316 L11.8363369,25.7231316 L17.952064,6.69165331 L24.0320014,25.7231316 L26.0476717,25.7231316 L29.8241905,13.8867947 C32.2707676,6.33089413 33.4403718,3.7669271 34.372333,3.7669271 C35.3028626,3.7669271 35.0752408,6.23640959 37.7909557,5.97013495 C38.9376545,5.8584714 40.0714691,4.82343613 40.0714691,3.49635772 C40.0714691,1.44489778 38.323505,-0.108370912 35.8153697,0.366914991" fill={color}></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default Logo
import React, { useState, useEffect } from "react";
import { Link } from "../../plugins/gatsby-plugin-atollon";

import Logo from "./logo";
import { SearchIcon, Facebook, Instagram, LinkedIn } from "./icons";

const Header = () => {
  const [offCanvas, setOffCanvas] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let isScrolled = window.scrollY > 1;
      if (isScrolled !== scrolled) {
        requestAnimationFrame(() => setScrolled(isScrolled));
      }

      const parallax = () => {
        const $images = document.querySelectorAll(
          ".image-1, .image-2, .image-3, .image-4, .image-5, .about__left, .about__right, .latest-listings__left, .latest-listings__right"
        );
        const viewHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight
        );
        const winBottom = viewHeight + window.scrollY;
        const speed = 0.4;
        const threshold = 0;

        for (let i = 0, l = $images.length; i < l; i++) {
          let rect = $images[i].getBoundingClientRect();
          let above = rect.bottom - threshold;
          let below = rect.top - viewHeight + threshold;

          if (above >= 0 && below < 0) {
            let parentH = $images[i].closest("section").offsetHeight;
            // Number of pixels shown after block appear
            let imgBottom = (winBottom - above) * speed;
            // Max number of pixels until block disappear
            let imgTop = winBottom + parentH;
            // Porcentage between start showing until disappearing
            let y = (imgBottom / imgTop) * 100;
            // Porcentage between start showing until disappearing
            $images[i].style.transform = `translate(0px, -${y}%)`;
          }
        }
      };
      requestAnimationFrame(parallax);
    };

    const scrollDetect = () => {
      clearTimeout(isScrolling);
      var isScrolling = setTimeout(handleScroll, 20);
    };

    window.addEventListener("scroll", scrollDetect);

    return () => {
      window.removeEventListener("scroll", scrollDetect);
    };
  }, [scrolled]);

  const toggleOffCanvas = () => {
    setOffCanvas(!offCanvas);
  };

  const toggleSearch = () => {
    setSearch(!search);
  };

  const hideOffCanvas = () => {
    setOffCanvas(false);
  };

  let props = {
    onClick: hideOffCanvas,
    activeClassName: "active",
  };

  let headerClass = "header";
  if (scrolled) headerClass += " header--scrolled";
  if (offCanvas) headerClass += " header--menu-open";

  return (
    <>
      <header className={headerClass}>
        <div className="header__inner">
          <Link to="/buy/" className="header__search-icon">
            <SearchIcon color={`#EFEEEA`} />
          </Link>
          <nav className="header__nav header__nav--left">
            <ul>
              <li>
                <Link to="/buy/" {...props}>
                  Buy
                </Link>
              </li>
              <li>
                <Link to="/lease/" {...props}>
                  Lease
                </Link>
              </li>
              <li>
                <Link to="/sold/" {...props}>
                  Sold
                </Link>
              </li>
              <li>
                <Link to="/appraisals/" {...props}>
                  Appraisals
                </Link>
              </li>
            </ul>
          </nav>
          <Link
            to="/"
            title="Wills Property"
            className="header__logo"
            {...props}
          >
            <Logo color={"#38291D"} />
          </Link>
          <nav className="header__nav header__nav--right">
            <ul>
              <li>
                <Link to="/our-story/" {...props}>
                  About
                </Link>
                <ul>
                  <li>
                    <Link to="/our-story/" {...props}>
                      Our Story
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/tenant-information/" {...props}>
                  Resources
                </Link>
                <ul>
                  <li>
                    <Link to="/tenant-information/" {...props}>
                      Tenancy Application
                    </Link>
                  </li>
                  <li>
                    <Link to="/repair-request/" {...props}>
                      Request a Repair
                    </Link>
                  </li>
                  <li>
                    <Link to="/vacate-notice/" {...props}>
                      Vacate a Property
                    </Link>
                  </li>
                  <li>
                    <Link to="/property-maintenance/" {...props}>
                      Property Maintenance
                    </Link>
                  </li>
                  <li>
                    <Link to="/tenant-information/" {...props}>
                      Tenant Info
                    </Link>
                  </li>
                  <li>
                    <Link to="/useful-links/" {...props}>
                      Useful Links
                    </Link>
                  </li>
                  <li>
                    <Link to="https://client.propertytree.com/login" {...props}>
                      Owners Portal
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/news/" {...props}>
                  News
                </Link>
              </li>
              <li>
                <Link to="/contact/" {...props}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
          <button
            onClick={toggleOffCanvas}
            className={
              offCanvas ? "header__hamburger active" : "header__hamburger"
            }
          >
            <span className="lines"></span>
          </button>
        </div>
      </header>
      <div className={`off-canvas ${offCanvas && "off-canvas--active"}`}>
        <div className="off-canvas__inner">
          <nav className="off-canvas__nav">
            <div className="off-canvas__col">
              <p>Property</p>
              <ul>
                <li>
                  <Link to="/buy/" {...props}>
                    Buy
                  </Link>
                </li>
                <li>
                  <Link to="/lease/" {...props}>
                    Lease
                  </Link>
                </li>
                <li>
                  <Link to="/sold/" {...props}>
                    Sold
                  </Link>
                </li>
                <li>
                  <Link to="/appraisals/" {...props}>
                    Appraisals
                  </Link>
                </li>
              </ul>
            </div>
            <div className="off-canvas__col">
              <p>Our Family</p>
              <ul>
                <li>
                  <Link to="/our-story/" {...props}>
                    Our Story
                  </Link>
                </li>
                <li>
                  <Link to="/news/" {...props}>
                    News
                  </Link>
                </li>
                <li>
                  <Link to="/contact/" {...props}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="off-canvas__col center">
              <p>Resources</p>
              <ul>
                <li>
                  <Link to="/tenant-information/" {...props}>
                    Tenancy Application
                  </Link>
                </li>
                <li>
                  <Link to="/repair-request/" {...props}>
                    Request a Repair
                  </Link>
                </li>
                <li>
                  <Link to="/vacate-notice/" {...props}>
                    Vacate a Property
                  </Link>
                </li>
                <li>
                  <Link to="/property-maintenance/" {...props}>
                    Property Maintenance
                  </Link>
                </li>
                <li>
                  <Link to="/tenant-information/" {...props}>
                    Tenant Info
                  </Link>
                </li>
                <li>
                  <Link to="/useful-links/" {...props}>
                    Useful Links
                  </Link>
                </li>
                <li>
                  <Link to="https://client.propertytree.com/login" {...props}>
                    Owners Portal
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <div className="off-canvas__bottom">
            <ul className="off-canvas__social">
              <li>
                <Link to="https://www.instagram.com/willspropertyau/?hl=en">
                  <Instagram color={"#EFEEEA"} />
                </Link>
              </li>
              <li>
                <Link to="https://www.linkedin.com/company/wills-property/">
                  <LinkedIn color={"#EFEEEA"} />
                </Link>
              </li>
              <li>
                <Link to="https://www.facebook.com/willsproperty.com.au/">
                  <Facebook color={"#EFEEEA"} />
                </Link>
              </li>
            </ul>
            <ul className="off-canvas__links">
              <li>
                <Link to="/terms-conditions/">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
